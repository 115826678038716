import { useMutation } from "react-query";
import "./interceptors";
import query from "./query";

const useApi = () => {
  const loginMutation = useMutation(details => {
    return query.post("/auth/login", details)
  });
  const registerMutation = useMutation(details => {
    return query.post("/users/register", details)
  });

  return {
    // mutations
    loginMutation,
    registerMutation,
  };
}

export default useApi;
import AdminHeader from "components/AdminHeader";
import AdminSidebar from "components/AdminSidebar";

const AdminLayout = ({ children }) => (
  <div className="fixed top-0 left-0 w-full h-full overflow-auto flex">
    <div className="container flex flex-col">
      <AdminHeader />
      <div className="py-4 flex-grow grid grid-cols-1 lg:grid-cols-[240px_auto]">
        <AdminSidebar />
        <div className="p-2  md:px-4 md:pb-4">{children}</div>
      </div>
    </div>
  </div>
);

export default AdminLayout;

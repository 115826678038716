import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AdminSidebarList = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const [menu, setMenu] = useState([
    {
      id: 1,
      name: "Vcc3 Statistics",
      to: "/u/statistics",
    },
    {
      id: 2,
      name: "Vcc Applications",
      to: "/u/applications",
    },
  ]);

  const handleClick = (item) => {
    navigate(item.to);
  };

  return (

    <div className="flex flex-col gap-y-4 mt-[9%] items-center">
      <div className="flex flex-col gap-3 classname= w-9/12">
        {menu.map((item) => {
          return (
            <button
              key={item.id}
              onClick={() => {
                handleClick(item);
              }}
              className="flex rounded-md h-10  items-center pl-5 pr-10 hover:scale-[102%]"
              style={{ backgroundColor: `${item.to}` === location?.pathname ? "#0068B1" : "#D9D9D9" }}
            >
              <img src={`${item.to}` === location?.pathname ? item.icon_w : item.icon_b} />
              <span
                className="text-xs ml-3"
                style={{ color: `${item.to}` === location?.pathname ? "#FFFFFF" : "#000000" }}
              >
                {item.name}
              </span>
            </button>
          );
        })}

      </div>
    </div>
  );
}

export default AdminSidebarList;
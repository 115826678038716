import query from "api/query";
// import Applications from "pages/Applications";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ApplicationsContext } from "./ApplicationsContext";

export const UserContext = createContext([]);
export const useUser = () => useContext(UserContext);
const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [hasApply, setHasApply] = useState();

  const currentUserQuery = useQuery(
    'currentUser',
    () => query.get("/auth/self"),
    {
      enabled: false,
    }
  );

  const fetchUser = useCallback(() => {
    currentUserQuery.refetch();
  }, []); // eslint-disable-line

  const value = useMemo(() => ({
    user,
    setUser,
    fetchUser,
    errorFetchingUser: currentUserQuery.isError,
  }), [
    user,
    setUser,
    fetchUser,
    currentUserQuery.isError
  ]);

  useEffect(() => {
    if (currentUserQuery.isSuccess) {
      setUser(currentUserQuery.data.data);
    }
  }, [currentUserQuery, currentUserQuery.isSuccess]);

  return (
    <UserContext.Provider value={value}>
      <ApplicationsContext.Provider value={{ hasApply, setHasApply }}>
        {children}
      </ApplicationsContext.Provider>
    </UserContext.Provider>
  )
}

export default UserProvider;
import React, { useEffect } from "react";
import GuestLayout from "layouts/guest";
import Login from "components/Login";

const LoginPage = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <GuestLayout title="Admin Login">
      <Login />
    </GuestLayout>
  );
};

export default LoginPage;

import React from 'react'
import { CSVLink } from 'react-csv';

const StatCard = ({ statNumber, title, description, handleClick, data, headers }) => {

    return (
        <div>
            <div className="bg-[#1d00bf10] hover:bg-[#1d00bf25] w-11/12 p-4 h-full flex flex-col rounded-md cursor-default select-none" onClick={handleClick}>
                <h1 className="text-[32pt] text-[#1a2752] font-[600]">{statNumber}</h1>
                <h3 className="font-semibold pb-2 text-[10pt] h-[60px]">{title}</h3>
                {/* <p className="text-[8pt] text-gray-500">
                    {description}
                </p> */}
                <CSVLink data={data} headers={headers} className="bg-[#188b1a] p-2 rounded-md text-center text-white">
                    Download File
                </CSVLink>
            </div>
        </div>
    )
}

export default StatCard;
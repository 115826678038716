import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "context/UserContext";

const Protected = ({ children }) => {
  const navigate = useNavigate();
  const { fetchUser, errorFetchingUser } = useUser();

  useEffect(() => {
    if (!localStorage.getItem("$token")) {
      navigate("/login");
    }
    else {
      fetchUser();
    }
  }, [navigate, fetchUser]);

  useEffect(() => {
    if (errorFetchingUser) {
      navigate("/login");
    }
  }, [errorFetchingUser, navigate]);

  return children;
};

export default Protected;
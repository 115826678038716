import axios from "axios";
import { useContext, useEffect, useState } from "react";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import { ApplicationsContext } from "context/ApplicationsContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";



const Applications = () => {
  const { hasApply } = useContext(ApplicationsContext)
  const [data, setData] = useState([])
  const [cdata, setCData] = useState([])
  const [icdata, setICData] = useState([])

  const [hasPillar, setHasPillar] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();



  // const handleApplyData = () => {
  //   axios.get('https://vcc3-backend.onrender.com/api/apply',)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setData(res.data)
  //       }
  //     })
  //     .catch((error) => {

  //     })

  // }
  const handleFilteredData = () => {
    axios.get('https://vcc3-backend.onrender.com/api/apply',)
      .then((res) => {
        if (res.status === 200) {
          const result = res.data.filter((i) => i.tsTsInvestors?.length)
          const resultDup = res.data.filter((i) => i?.email !== i?.email)
          // console.log('my filters', result)
          // console.log('my dups', resultDup)


          const uniqueArr = res.data.reduce((acc, current) => {
            const x = acc.find(item => item.email === current.email);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);


          const x = uniqueArr.filter((i) => i.tsTsInvestors?.length > 0)
          const z = uniqueArr.filter((i) => i.tsTsInvestors === undefined)
          // const a = uniqueArr.filter((i) => (i.tsTsInvestors).length - (i.tsTsInvestors === undefined).length)

          const y = uniqueArr.filter((i) => i.businessType?.length > 0)

          // console.log('uuuuuu', y)


          setData(uniqueArr)
          setCData(x)
          setHasPillar(y)
          setICData(z)
          // setDup()
        }
      })
      .catch((error) => {

      })

  }
  const handleUsersData = () => {

    axios.get('https://vcc3-backend.onrender.com/api/user',)
      .then((res) => {
        if (res.status === 200) {
          const uniqueArr = res.data.reduce((acc, current) => {
            const x = acc.find(item => item.email === current.email);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          setUsers(uniqueArr)

        }
      })
      .catch((error) => {

      })

  }

  const getPillarData = (arr, pillar) => {

    const data = arr.filter((i) => i.businessType === pillar)
    return data

  }

  const fintech = getPillarData(hasPillar, "Financial Technology (Fintech)")
  const creative = getPillarData(hasPillar, "Creative  Industry")
  const sust = getPillarData(hasPillar, "Sustainablity Pillar")
  const emerge = getPillarData(hasPillar, "Emerging Technology")
  const cfintech = getPillarData(cdata, "Financial Technology (Fintech)")
  const ccreative = getPillarData(cdata, "Creative  Industry")
  const csust = getPillarData(cdata, "Sustainablity Pillar")
  const cemerge = getPillarData(cdata, "Emerging Technology")
  const cNoPilla = getPillarData(cdata, undefined)


  console.log('NoPilla', cNoPilla);
  // const completeApplications = () => {

  //   const newData = []

  //   const result = data.filter((i) => i.tsTsInvestors?.length)



  //   console.log('====================================');
  //   console.log(result);
  //   console.log('====================================');
  //   return result
  // }
  const getData = async () => {
    setLoading(true)
    // await handleApplyData()
    await handleUsersData()
    await handleFilteredData()
    setLoading(false)
  }







  useEffect(() => {
    getData()
  }, [])

  // if (data) {
  //   completeApplications()
  // }
  console.log(hasApply);


  return (
    <>
      {
        loading ? <div>fetching data...</div> :
          <div>
            <h1 className="font-[600] pb-[1%]">All Submitted Applications</h1>

            <div className=" pt-3 overflow-scroll flex flex-col gap-y-8 " style={{ height: `calc(100vh - 180px)` }}>

              {loading ? <div>fetching data...</div> : (
                hasApply?.map((item) => {
                  console.log('each item', item.userInfo?.firstname)
                  return (
                    <div className="bg-[#f5f5f5] hover:bg-[#e0e0e0] cursor-pointer py-[2%] px-[1%] grid grid-cols-4  rounded-md"
                      onClick={() => {
                        navigate(`/u/applications/dataprint`, { state: { user: item } });
                      }}
                    >
                      <div className="capitalize">{item.userInfo?.firstname + " " + item.userInfo?.surname}</div>
                      {/* <div className="capitalize overflow-clip">{item.businessName?.substring(0, 20) + '...'}</div> */}
                      <div className="capitalize overflow-clip">{item.businessName?.length > 20 ? (item.businessName?.substring(0, 20) + '...') : item?.businessName}</div>
                      <div className="capitalize">{item.businessType?.substring(0, 20)} </div>
                      <div className="overflow-clip lowercase">{item.email?.length > 20 ? (item.email.substring(0, 20) + '...') : item.email}</div>

                    </div>


                  )
                }))}


            </div >
          </div>
      }

    </ >
  );
}
export default Applications;
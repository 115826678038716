import axios from "axios";
import { endpoint } from "./endpoint";

const options = {
  get: (url, params, headers) =>
    axios.get(endpoint(url), {
      params,
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  post: (url, data, headers) =>
    axios.post(endpoint(url), data, {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  put: (url, data, headers) =>
    axios.put(endpoint(url), data, {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  patch: (url, data, headers) =>
    axios.patch(endpoint(url), data, {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  delete: (url, headers) =>
    axios.delete(endpoint(url), {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
};


export default options;
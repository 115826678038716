import axios from "axios";
import StatCard from "components/StatCard";
import { ApplicationsContext } from "context/ApplicationsContext";
import { useContext, useEffect, useState } from "react";
// import AdminLayout from "../layouts/admin";

const Statistics = () => {
  const { setHasApply } = useContext(ApplicationsContext)
  const [data, setData] = useState([])
  const [cdata, setCData] = useState([])
  const [icdata, setICData] = useState([])

  const [hasPillar, setHasPillar] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)



  // const handleApplyData = () => {
  //   axios.get('https://vcc3-backend.onrender.com/api/apply',)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setData(res.data)
  //       }
  //     })
  //     .catch((error) => {

  //     })

  // }
  const handleFilteredData = () => {
    axios.get('https://vcc3-backend.onrender.com/api/apply',)
      .then((res) => {
        if (res.status === 200) {
          const result = res.data.filter((i) => i.tsTsInvestors?.length)
          const resultDup = res.data.filter((i) => i?.email !== i?.email)
          // console.log('my filters', result)
          // console.log('my dups', resultDup)


          const uniqueArr = res.data.reduce((acc, current) => {
            const x = acc.find(item => item.email === current.email);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);


          const x = uniqueArr.filter((i) => i.tsTsInvestors?.length > 0)
          const z = uniqueArr.filter((i) => i.tsTsInvestors === undefined)
          // const a = uniqueArr.filter((i) => (i.tsTsInvestors).length - (i.tsTsInvestors === undefined).length)

          const y = uniqueArr.filter((i) => i.businessType?.length > 0)

          // console.log('uuuuuu', y)


          setData(uniqueArr)
          setCData(x)
          setHasPillar(y)
          setICData(z)
          setHasApply(x)
          // setDup()
        }
      })
      .catch((error) => {

      })

  }
  const handleUsersData = () => {

    axios.get('https://vcc3-backend.onrender.com/api/user',)
      .then((res) => {
        if (res.status === 200) {
          const uniqueArr = res.data.reduce((acc, current) => {
            const x = acc.find(item => item.email === current.email);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          setUsers(uniqueArr)

        }
      })
      .catch((error) => {

      })

  }

  const getPillarData = (arr, pillar) => {

    const data = arr.filter((i) => i.businessType === pillar)
    return data

  }

  const fintech = getPillarData(hasPillar, "Financial Technology (Fintech)")
  const creative = getPillarData(hasPillar, "Creative  Industry")
  const sust = getPillarData(hasPillar, "Sustainablity Pillar")
  const emerge = getPillarData(hasPillar, "Emerging Technology")
  const cfintech = getPillarData(cdata, "Financial Technology (Fintech)")
  const ccreative = getPillarData(cdata, "Creative  Industry")
  const csust = getPillarData(cdata, "Sustainablity Pillar")
  const cemerge = getPillarData(cdata, "Emerging Technology")
  const cNoPilla = getPillarData(cdata, undefined)


  console.log('NoPilla', cNoPilla);
  // const completeApplications = () => {

  //   const newData = []

  //   const result = data.filter((i) => i.tsTsInvestors?.length)



  //   console.log('====================================');
  //   console.log(result);
  //   console.log('====================================');
  //   return result
  // }
  const getData = async () => {
    setLoading(true)
    // await handleApplyData()
    await handleUsersData()
    await handleFilteredData()
    setLoading(false)
  }







  useEffect(() => {
    getData()
  }, [])

  // if (data) {
  //   completeApplications()
  // }


  return (

    <>
      {
        loading ? <div>fetching data...</div> :
          <div className=" pt-3 overflow-scroll flex flex-col gap-y-8" style={{ height: `calc(100vh - 150px)` }}>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4">
              <StatCard statNumber={users?.length} title={'Total Registered Users'} description={'The total number of users that have at least registerd an accont'} data={users} />
              <StatCard statNumber={data?.length} title={'Total Number of Applications'} description={'The total number of users that have at least registerd an accon'} data={data} />
              <StatCard statNumber={cdata?.length} title={'Total Completed Applications'} description={'The total number of users that have at least registerd an accon'} data={cdata} />
              <StatCard statNumber={icdata?.length} title={'Total Incomplete Applications'} description={'The total number of users that have at least registerd an accon'} data={icdata} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4">
              <StatCard statNumber={fintech?.length} title={'Total Fintech Applications'} description={'The total number of users that have at least registerd an accon'} data={fintech} />
              <StatCard statNumber={creative?.length} title={'Total Creative Industry Applications'} description={'The total number of users that have at least registerd an accon'} data={creative} />
              <StatCard statNumber={sust?.length} title={'Total Sustainability Applications'} description={'The total number of users that have at least registerd an accon'} data={sust} />
              <StatCard statNumber={emerge?.length} title={'Total Emerging Technology Applications'} description={'The total number of users that have at least registerd an accon'} data={emerge} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4">
              <StatCard statNumber={cfintech?.length + cNoPilla?.length} title={'Completed Fintech Applications'} description={'The total number of users that have at least registerd an accon'} data={cfintech} />
              <StatCard statNumber={ccreative?.length} title={'Completed Creative Industry Applications'} description={'The total number of users that have at least registerd an accon'} data={ccreative} />
              <StatCard statNumber={csust?.length} title={'Completed Sustainability Applications'} description={'The total number of users that have at least registerd an accon'} data={csust} />
              <StatCard statNumber={cemerge?.length} title={'Completed Emerging Technology Applications'} description={'The total number of users that have at least registerd an accon'} data={cemerge} />
            </div>

          </div >

      }
    </>
  );
}
export default Statistics;
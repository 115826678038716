import logo from "assets/logo.png";

const GuestLayout = ({ children, title }) => (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
    <div className="w-full h-full sm:h-auto sm:max-w-[340px] flex flex-col gap-y-4 p-4 py-20 md:py-4 overflow-auto">
      <div className="flex items-center justify-center">
        <img src={logo} className="w-[90%]" />
      </div>
      <div className="mt-10">
        <h3 className="text-center mb-10 font-bold text-gray-800">{title}</h3>
        {children}
      </div>
    </div>
  </div>
)

export default GuestLayout;
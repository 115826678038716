import Protected from "components/Protected";
import { ApplicationsContext } from "context/ApplicationsContext";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../layouts/admin";
import Applications from "./Applications";
import Statistics from "./Statistics";
import ApplicantPrint from "./ApplicantPrint";

const Dashboard = () => {
  const { page, id } = useParams();

  const getCurrentPage = useCallback(() => {
    switch (page) {
      case "applications": {
        console.log(id)
        if (id) {
          return <ApplicantPrint />;
        }
        return <Applications />;
      }
      case "statistics":
      default:
        return <Statistics />;
    }
  }, [page, id]);

  return (
    <Protected>
      <AdminLayout>
        {getCurrentPage()}
      </AdminLayout>
    </Protected>
  );
};

export default Dashboard;
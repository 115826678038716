import { useEffect, useRef } from "react";

const Drawer = ({
  isOpen,
  onClose,
  handleLogout,
  children
}) => {
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose(event);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside, true);
    }
    else {
      document.removeEventListener('click', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen]); // eslint-disable-line

  return (
    <div className={`fixed top-0 right-0 bottom-0 left-0 z-[2231239] backdrop-brightness-50 overflow-hidden ${isOpen ? "translate-x-0" : "translate-x-full"}`}>
      <div
        ref={ref}
        className={`w-[340px] max-w-full bg-white absolute top-0 right-0 bottom-0 overflow-hidden transition ease-in-out duration-200 p-8 ${isOpen ? "translate-x-0" : "translate-x-full"}`}
      >
        <div className='p-3 my-2' onClick={onClose}>
          <button className="text-lg bg-gray-300 p-2 hover:scale-110 transition duration-200 ease-in-out">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" /> <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" /> </svg>
          </button>
        </div>
        {children}

        <hr className="my-4" />
        <span
          className='block mx-3 rounded-[5px] w-[90%] p-[9px] px-4 text-[15px] transition ease-in-out duration-200 cursor-pointer text-[#333333] hover:text-black hover:bg-gray-300'
          onClick={() => {
            onClose();
            handleLogout();
          }}
        >
          Log out
        </span>
      </div>
    </div>
  )
}

export default Drawer;
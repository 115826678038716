import useApi from "api";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from "./TextField";

const Login = () => {
  const [details, setDetails] = useState({});
  const navigate = useNavigate();
  const { loginMutation } = useApi();

  const handleChange = (field, value) => {
    loginMutation.reset();
    setDetails(d => ({
      ...d,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    loginMutation.reset();
    loginMutation.mutate(details, {
      onSuccess: ({ data }) => {
        localStorage.setItem("$token", data.token);
        navigate("/u/statistics");
      }
    });
  };

  return (
    <>
      {loginMutation.isError ? <p className="text-red-500 mb-3 text-sm">Login failed</p> : ""}
      <TextField
        id="email"
        placeholder="eg. jane@gmail.com"
        label="Email"
        onChange={(e) => handleChange("email", e.target.value)}
      />
      <TextField
        id="password"
        label="Password"
        type="password"
        onChange={(e) => handleChange("password", e.target.value)}
      />

      <a href="/forgot-password" className="inline-block align-baseline font-[400] text-xs text-blue-100 hover:text-blue-300">
        Forgot Password?
      </a>
      <div className="flex items-center justify-between mt-10">
        <button
          className="bg-blue-100 text-[14px] hover:bg-blue-300 text-white font-[500] py-2 px-4 rounded transition duration-200 ease-in-out hover:scale-105"
          type="button"
          onClick={handleSubmit}
          disabled={loginMutation.isLoading}
        >
          {loginMutation.isLoading ? "Verifying..." : "Log in"}
        </button>
        <Link to="/register" className="text-blue-100 text-xs">Don't have an account?</Link>
      </div>
    </>
  );
};

export default Login;
import logo from "assets/logo.png";
import { useUser } from "context/UserContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminSidebarList from "./AdminSidebarList";
import Drawer from "./Drawer";

const AdminHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();

  const toggleDrawer = () => setDrawerOpen(x => !x);
  
  const handleLogout = () => {
    navigate("/login");
  };

  return (
    <>
    <div className="sticky top-0 grid grid-cols-[auto_50px] lg:grid-cols-[240px_auto_240px] py-5 border-b">
      <div className="flex items-center justify-start">
        <img src={logo} className="h-[30px] lg:h-[40px] w-auto" alt="logo" />
      </div>
      <div className="hidden lg:flex items-center">
        <input className="border w-full h-[44px] text-[15px] px-3" placeholder="Search"></input>
      </div>
      <div className="hidden lg:flex justify-end items-center">
        <div className="flex-grow flex justify-center">
          <div className="flex flex-col p-3">
            <div className="text-sm">{user?.fullName}</div>
            <div className="text-xs text-gray-500">
              {user?.role === "admin" ? "Super-Admin" : "Admin"}
            </div>
          </div>
        </div>
        <button className="w-[90px] h-[40px] text-[15px] rounded-[5px] bg-[#0068B1] hover:to-blue-600 text-white hover:scale-105 transition ease-in-out duration-200" onClick={handleLogout}>Logout</button>
      </div>
      <div className="lg:hidden flex justify-end items-center cursor-pointer hover:scale-110 transition ease-in-out duration-200" onClick={toggleDrawer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#7A7A7A" d="M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm7-7a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z" /></svg>
      </div>
    </div>
    <Drawer isOpen={drawerOpen} onClose={toggleDrawer} handleLogout={handleLogout}>
      <AdminSidebarList />
    </Drawer>
    </>    
  );
};

export default AdminHeader;

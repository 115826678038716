import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import UserProvider from './context/UserContext';
import Login from "pages/Login";
import Register from "pages/Register";
import Dashboard from "pages/Dashboard";
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';


const queryClient = new QueryClient();

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <UserProvider>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route path="/u/:page/:id" element={<Dashboard />} />
            <Route path="/u/:page" element={<Dashboard />} />

            <Route path="*" element={<Login />} />
          </Routes>
        </UserProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
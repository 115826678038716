import TextField from "./TextField";
import { Link, useNavigate } from "react-router-dom";
import useApi from "api";
import { useEffect, useState } from "react";

const Registration = () => {
  const [details, setDetails] = useState({});
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const { registerMutation } = useApi();

  const handleChange = (field, value) => {
    registerMutation.reset();
    setError();
    setDetails(d => ({
      ...d,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    console.log(details)
    if (details.passwordConfirmation !== details.password) {
      setError("Passwords do not match");
      return;
    }

    const { passwordConfirmation, ...actualDetails } = details;

    registerMutation.reset();
    registerMutation.mutate(actualDetails, {
      onSuccess: ({ data }) => {
        localStorage.setItem("$token", data.token);
        navigate("/u/statistics");
      },
    });
  };

  useEffect(() => {
    if (registerMutation.isError) {
      if (registerMutation.error.response?.status === 403) {
        setError("Email already taken");
      }
      else if (registerMutation.error.response?.status === 400) {
        setError("Please enter valid details");
      }
      else {
        setError("Unknown error occurred");
      }
    }
  }, [registerMutation?.isError, registerMutation?.error?.response?.status]); // eslint-di

  return (
    <>
      {error ? <p className="text-red-500 mb-3 text-sm">{error}</p> : ""}
      <TextField
        id="fullName"
        label="Full Name"
        placeholder="eg. Jane Doe"
        onChange={(e) => handleChange("fullName", e.target.value)}
      />
      <TextField
        id="email"
        label="Email"
        placeholder="eg. jane@gmail.com"
        onChange={(e) => handleChange("email", e.target.value)}
      />
      <TextField
        id="phoneNumber"
        label="Phone Number"
        onChange={(e) => handleChange("phoneNumber", e.target.value)}
      />
      <TextField
        id="password"
        label="Password"
        type="password"
        onChange={(e) => handleChange("password", e.target.value)}
      />
      <TextField
        id="passwordConfirmation"
        label="Confirm Password"
        type="password"
        onChange={(e) => handleChange("passwordConfirmation", e.target.value)}
      />
      <div className="flex items-center justify-between mt-5">
        <button
          className="bg-blue-100 text-[14px] hover:bg-blue-300 text-white font-[500] py-2 px-4 rounded transition duration-200 ease-in-out hover:scale-105"
          type="button"
          disabled={registerMutation.isLoading}
          onClick={handleSubmit}
        >
          {registerMutation.isLoading ? "Registering..." : "Register"}
        </button>
        <Link to="/" className="text-blue-100 text-xs">Already have an account?</Link>
      </div>
    </>
  );
};

export default Registration;

import { json, useLocation } from 'react-router-dom';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';



function ApplicantPrint() {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const location = useLocation();
    const details = location.state.user;

    console.log('my details', details);

    return (
        <div className=' w-[100%]' >
            <div className='flex w-[100%] justify-between py-4 border-b-[1px] border-[#7f7f7f]' >
                <h1 className='font-semibold text-lg uppercase'>
                    VCC3 Application for {details?.userInfo.firstname + " " + details?.userInfo.surname}
                </h1>
                <button className='px-5 py-2 bg-[#0068B1] rounded-md text-white' onClick={handlePrint}>
                    Print to PDF
                </button>
            </div>
            <div className='overflow-scroll' style={{ height: `calc(100vh - 200px)` }} >
                <div ref={componentRef}>
                    <title>VCC3 Application for {details?.userInfo.firstname + " " + details?.userInfo.surname}</title>
                    <div id='application-for-pdf' className=' px-5 py-10 ' >
                        <h1 className='font-medium text-xl mb-10 capitalize text-[#49a031]'>
                            VCC3 Application for {details?.userInfo.firstname + " " + details?.userInfo.surname}
                        </h1>
                        <div id='Personal Information' className=' mt-3 pb-3  border-b-[1px] border-[#7f7f7f]'>
                            <h1 className='font-semibold text-lg text-[#868686] mb-4'>
                                Personal Information
                            </h1>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Name(s) & Surname:
                                </p>
                                <p className=' text-base'>
                                    {details?.userInfo.firstname + " " + details?.userInfo.surname}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Date of Birth
                                </p>
                                <p className=' text-base'>
                                    {details?.dob}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Gender
                                </p>
                                <p className=' text-base'>
                                    {details?.gender}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Phone Number
                                </p>
                                <p className=' text-base'>
                                    {details?.phone}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Email
                                </p>
                                <p className=' text-base'>
                                    {details?.email}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    WhatsApp Number
                                </p>
                                <p className=' text-base'>
                                    {details?.whatsapp}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Physical Address
                                </p>
                                <p className=' text-base'>
                                    {details?.address}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Do you have Access to internet
                                </p>
                                <p className=' text-base'>
                                    {details?.internetAccess}
                                </p>
                            </div>

                        </div>

                        <div id='Personal Information' className=' mt-8 pb-3 border-b-[1px] border-[#7f7f7f]'>
                            <h1 className='font-semibold text-lg text-[#868686] mb-4'>
                                Business Information
                            </h1>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Name of Business
                                </p>
                                <p className=' text-base'>
                                    {details?.businessName}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Start Date
                                </p>
                                <p className=' text-base'>
                                    {details?.businessStartDate}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Business Email
                                </p>
                                <p className=' text-base'>
                                    {details?.businessEmail}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Physical Address
                                </p>
                                <p className=' text-base'>
                                    {details?.businessAddress}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    What pillar does your business fall under?
                                </p>
                                <p className=' text-base'>
                                    {details?.businessType}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Is your business registered?
                                </p>
                                <p className='text-base'>
                                    {details?.businessRegType}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    If your business is registered , please advise legal structure.
                                </p>
                                <p className=' text-base'>
                                    {details?.businessDescStructure}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Please provide a brief description about your business?
                                </p>
                                <p className=' text-base'>
                                    {details?.businessDesc}
                                </p>
                            </div>

                        </div>
                        <div id='Personal Information' className=' mt-8 pb-3 border-b-[1px] border-[#7f7f7f]'>
                            <h1 className='font-semibold text-lg text-[#868686] mb-4'>
                                Product Business Case
                            </h1>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    What problem does your company solve? (500 words only)
                                </p>
                                <p className=' text-base'>
                                    {details?.productDesc}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    What is the selling price of your product or service?
                                </p>
                                <p className=' text-base'>
                                    {details?.productPrice}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    What is the profit margin realised on each unit sold?
                                </p>
                                <p className=' text-base'>
                                    {details?.productProfit}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    How much revenue are you generating annually?
                                </p>
                                <p className=' text-base'>
                                    {details?.productRevenue}
                                </p>
                            </div>

                        </div>
                        <div id='Personal Information' className=' mt-8 pb-3 border-b-[1px] border-[#7f7f7f]'>
                            <h1 className='font-semibold text-lg text-[#868686] mb-4'>
                                Market Information Section
                            </h1>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Who is your target market (500 words only)
                                </p>
                                <p className=' text-base'>
                                    {details?.marketTarget}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Do you currently have customers?
                                </p>
                                <p className=' text-base'>
                                    {details?.marketCustomer}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    How are you distributing your product/ services?
                                </p>
                                <p className=' text-base'>
                                    {details?.marketDistribution}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    What is your unique value proposition?
                                </p>
                                <p className=' text-base'>
                                    {details?.marketValue}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Who are your main competitors?
                                </p>
                                <p className=' text-base'>
                                    {details?.marketCompetition}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Do you currently have a patent, or plan to patent your product/ service?
                                </p>
                                <p className=' text-base'>
                                    {details?.marketPatent}
                                </p>
                            </div>

                        </div>
                        <div id='Personal Information' className=' mt-8 pb-3 border-b-[1px] border-[#7f7f7f]'>
                            <h1 className='font-semibold text-lg text-[#868686] mb-4'>
                                Team And Skills Information
                            </h1>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Tell us about yourself and your qualifications?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsSkills}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Tell us about your key team members and their roles?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsMembers}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    How many employees are in your company?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsNumber}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Why do you want to participate in VCC 2023?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsParticipate}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Have you previously entered VCC?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsPrevious}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Have you won any awards or competitions?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsAwards}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Do you currently have any inverstors that have invested in your business?
                                </p>
                                <p className=' text-base'>
                                    {details?.tsTsInvestors}
                                </p>
                            </div>
                            <div id='question_answer' className='mb-5'>
                                <p className='font-semibold text-base mb-3'>
                                    Additional information that you will want the adjudication committee to know.
                                </p>
                                <p className=' text-base'>
                                    {details?.tsAdditions}
                                </p>
                            </div>

                        </div>


                    </div>
                </div>

            </div>


            <div className='w-[10%]'></div>
        </div>
    )
}

export default ApplicantPrint
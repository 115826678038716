import React from "react";
import GuestLayout from "layouts/guest";
import Registration from "components/Registration";

const RegistrationPage = () => {
  return (
    <GuestLayout title="Admin Registration">
      <Registration />
    </GuestLayout>
  );
};

export default RegistrationPage;

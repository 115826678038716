const TextField = ({
  id,
  label,
  placeholder,
  type="text",
  ...props
}) => (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-[600] mb-2" for={id}>
      {label}
    </label>
    <input
      className="appearance-none w-full p-2 text-[14px] border-b-2 outline-none focus:border-b-blue-200"
      id={id}
      type={type}
      placeholder={placeholder || ""}
      {...props}
    />
  </div>
);

export default TextField;